<template>
    <div class="flex flex-col border rounded-lg bg-gray-50  py-4"
         :class="{
                'w-full': isSetup,
                'lg:w-[400px] px-6': !isSetup
            }"
    >
        <p class="text-3xl font-bold text-black mb-5">
            Custom
            <br>
            <AppBadge class="mr-1 bg-primary text-white">
                Outbound Calls
            </AppBadge>
            <AppBadge>
                Workflows
            </AppBadge>
        </p>

        <table class="text-left align-middle">
            <tr class="align-middle">
                <td class="pr-4 pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                    Everything in Business Pro
                </td>
            </tr>

            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            For high call volume
                                        </span>
                </td>
            </tr>

            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            Outbound calls and campaigns
                                        </span>
                </td>
            </tr>

            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                        <span>
                            <b>Priority</b> support
                        </span>
                </td>
            </tr>

            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                        <span>
                            <b>HIPAA</b> compliant plans available
                        </span>
                </td>
            </tr>

            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                        <span>
                            <b>Free</b> onboarding
                        </span>
                </td>
            </tr>

            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                        <span>
                            <b>More</b> minutes included
                        </span>
                </td>
            </tr>

            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-5"/>
                </td>
                <td class="pb-2">
                    No outbound call limit
                </td>
            </tr>
        </table>

        <hr class="mt-auto mb-3">

        <div class="py-4 lg:pb-16 flex items-center justify-center gap-x-6">
            <PrimaryButton
                @click="sales"
                class="scale-125 lg:scale-150 origin-center">
                Talk To Sales
            </PrimaryButton>
        </div>

    </div>

</template>
<script setup lang="ts">
import logoBlack from "../../../../images/logo-black.svg";
import {router, usePage} from "@inertiajs/vue3";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {CheckCircleIcon} from "@heroicons/vue/20/solid/index";
import AppBadge from "@/Components/AppBadge.vue";
import {mdiCloseCircleOutline} from "@mdi/js";
import MdiIcon from "@/Components/MdiIcon.vue";
//import {route} from "ziggy-js";
const products = usePage().props.products


defineProps(['isSetup', 'loading'])

// const emit = defineEmits(['submit'])

const sales = () => {
    router.visit(route('post', {slug: 'sales'}))
}
</script>
